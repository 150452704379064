// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-services-design-js": () => import("./../../../src/pages/services/design.js" /* webpackChunkName: "component---src-pages-services-design-js" */),
  "component---src-pages-services-development-js": () => import("./../../../src/pages/services/development.js" /* webpackChunkName: "component---src-pages-services-development-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-mobileapps-js": () => import("./../../../src/pages/services/mobileapps.js" /* webpackChunkName: "component---src-pages-services-mobileapps-js" */),
  "component---src-pages-services-operations-js": () => import("./../../../src/pages/services/operations.js" /* webpackChunkName: "component---src-pages-services-operations-js" */),
  "component---src-pages-services-quality-js": () => import("./../../../src/pages/services/quality.js" /* webpackChunkName: "component---src-pages-services-quality-js" */),
  "component---src-pages-services-testing-js": () => import("./../../../src/pages/services/testing.js" /* webpackChunkName: "component---src-pages-services-testing-js" */),
  "component---src-pages-services-training-js": () => import("./../../../src/pages/services/training.js" /* webpackChunkName: "component---src-pages-services-training-js" */),
  "component---src-pages-services-webapps-js": () => import("./../../../src/pages/services/webapps.js" /* webpackChunkName: "component---src-pages-services-webapps-js" */),
  "component---src-pages-services-websites-js": () => import("./../../../src/pages/services/websites.js" /* webpackChunkName: "component---src-pages-services-websites-js" */)
}

